import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';

const YourPlan = () => {
  const [plan, setPlan] = useState(null);
  const [availablePlans] = useState(['Basic - $15', 'Plus - $30', 'Premium - $50']);
  const [newPlan, setNewPlan] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser; // Get the currently authenticated user
      if (user) {
        fetchPlan(user.email); // Use email instead of sessionTag
      } else {
        setMessage('User not authenticated.');
        setLoading(false);
      }
    };

    const fetchPlan = async (email) => {
      try {
        // Fetch the user's purchase plan using email from Firestore
        const purchasesSnapshot = await db.collection('purchases')
          .where('email', '==', email) // Query using email instead of sessionTag
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get();

        if (!purchasesSnapshot.empty) {
          const purchaseDoc = purchasesSnapshot.docs[0];
          setPlan(purchaseDoc.data().plan); // Set the plan from the fetched data
        } else {
          setMessage('No purchase plan found.');
        }
      } catch (error) {
        console.error('Error fetching purchase plan:', error);
        setMessage('Error fetching purchase plan. Please try again.');
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleUpgrade = async () => {
    if (!newPlan) {
      setMessage('Please select a new plan.');
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        // Fetch the user's purchase document using email
        const purchasesSnapshot = await db.collection('purchases')
          .where('email', '==', user.email)
          .get();

        if (!purchasesSnapshot.empty) {
          const purchaseDoc = purchasesSnapshot.docs[0];

          // Update the plan and timestamp
          await purchaseDoc.ref.update({
            plan: newPlan,
            timestamp: Date.now(),
          });

          setPlan(newPlan);
          setMessage('Plan updated successfully!');
        } else {
          setMessage('No existing purchase found to update.');
        }
      }
    } catch (error) {
      console.error('Error updating plan:', error);
      setMessage('Error updating your plan. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen p-8 bg-gray-200">
      <div className="p-8 bg-white shadow rounded w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">My Plan</h2>
        {loading ? (
          <p>Loading...</p>
        ) : plan ? (
          <>
            <h3 className="text-lg font-semibold">Current Plan: {plan}</h3>
            <p className="mt-2">You are currently subscribed to the {plan} plan.</p>
            <div className="mt-4">
              <label className="block mb-2">Select New Plan:</label>
              <select 
                value={newPlan} 
                onChange={(e) => setNewPlan(e.target.value)} 
                className="w-full p-2 border rounded mb-4"
              >
                <option value="" disabled>Select a plan</option>
                {availablePlans.map((planOption) => (
                  <option key={planOption} value={planOption}>
                    {planOption}
                  </option>
                ))}
              </select>
            </div>
            <button 
              onClick={handleUpgrade}
              className="bg-[#2E6C83] text-white p-2 rounded w-full"
            >
              Upgrade Plan
            </button>
            {message && <p className="mt-4 text-center text-green-500">{message}</p>}
          </>
        ) : (
          <p>{message || 'You have not purchased a plan yet.'}</p>
        )}
      </div>
    </div>
  );
};

export default YourPlan;

import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // For password confirmation
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check access and session tag validation
  useEffect(() => {
    const checkAccess = async () => {
      try {
        const storedUID = localStorage.getItem('userUID');
        if (storedUID) {
          setAllowed(true);
          setLoading(false);
          return;
        }

        const userIP = await fetch('https://api.ipify.org?format=json')
          .then((res) => res.json())
          .then((data) => data.ip);

        const ipDoc = await db.collection('loggedIPs').doc(userIP).get();
        if (ipDoc.exists) {
          setAllowed(true);
          setLoading(false);
          return;
        }

        const urlParams = new URLSearchParams(location.search);
        const sessionTag = urlParams.get('session');

        if (sessionTag) {
          localStorage.setItem('sessionTag', sessionTag); // Store sessionTag in localStorage
        }

        if (!sessionTag) {
          navigate('/error');
          return;
        }

        const purchaseQuery = db.collection('purchases').where('sessionTag', '==', sessionTag);
        const purchaseSnapshot = await purchaseQuery.get();

        if (purchaseSnapshot.empty) {
          alert('Invalid session tag. Please contact support.');
          navigate('/error');
          return;
        }

        const purchaseDoc = purchaseSnapshot.docs[0];
        const purchaseData = purchaseDoc.data();

        if (purchaseData.usesLeft <= 0) {
          alert('This link has expired.');
          navigate('/error');
          return;
        }

        await purchaseDoc.ref.update({ usesLeft: purchaseData.usesLeft - 1 });
        await db.collection('loggedIPs').doc(userIP).set({ sessionTag, timestamp: Date.now() });

        setAllowed(true);
        setLoading(false);
      } catch (error) {
        console.error('Error in access validation:', error);
        setError('An error occurred during access validation. Please try again.');
        navigate('/error');
      }
    };

    checkAccess();
  }, [location.search, navigate]);

  // Listen for auth changes and check for onboarding data
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await db.collection('users').doc(user.uid).get();
          // If no user data exists in the DB, redirect to onboarding
          if (!userDoc.exists) {
            navigate('/onboarding');
          } else {
            navigate('/');
          }
        } catch (error) {
          console.error('Error checking onboarding data:', error);
          navigate('/');
        }
      } else {
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleEmailValidation = async () => {
    try {
      const purchaseQuery = await db.collection('purchases').where('email', '==', email).get();
      if (purchaseQuery.empty) {
        setError('Email not found. Please contact support or use the same email entered when purchasing your plan.');
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error during email validation:', error);
      setError('Error checking email. Please contact support.');
      return false;
    }
  };

  const handleEmailAuth = async () => {
    setError(null);

    // Validate the email against purchases collection
    const isEmailValid = await handleEmailValidation();
    if (!isEmailValid) return;

    // For sign-up, check password confirmation
    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      if (isLogin) {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        localStorage.setItem('userUID', userCredential.user.uid);
        // On login, the onAuthStateChanged effect will redirect accordingly.
      } else {
        const userCredential = await auth.createUserWithEmailAndPassword(email, password);
        await userCredential.user.sendEmailVerification();
        alert('A verification link has been sent to your email.');

        // For sign-up, we already want to onboard the user
        navigate('/onboarding');

        const sessionTag = localStorage.getItem('sessionTag') || 'N/A';
        const userIP = await fetch('https://api.ipify.org?format=json')
          .then((res) => res.json())
          .then((data) => data.ip);

        const userData = {
          email: userCredential.user.email,
          uid: userCredential.user.uid,
          sessionTag,
          ipAddress: userIP,
          createdAt: new Date(),
        };

        // Save initial user data
        await db.collection('users-auth').doc(userCredential.user.uid).set(userData);
        await db.collection('users').doc(userCredential.user.uid).set(userData);

        localStorage.setItem('userUID', userCredential.user.uid);
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setError(error.message);
    }
  };

  const handlePasswordReset = async () => {
    setError(null);

    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }

    try {
      const sessionTag = localStorage.getItem('sessionTag') || 'N/A';
      const userIP = await fetch('https://api.ipify.org?format=json')
        .then((res) => res.json())
        .then((data) => data.ip);

      // Log the reset request in Firestore
      await db.collection('password-resets').doc(email).set({
        email,
        sessionTag,
        ipAddress: userIP,
        requestedAt: new Date(),
      });

      await auth.sendPasswordResetEmail(email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!allowed) return <div>Loading...</div>;

  return (
    <div className="flex items-center justify-center w-screen h-screen p-8 bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg w-96 max-w-full">
        <h2 className="text-2xl font-bold mb-4 text-center">{isLogin ? 'Login' : 'Sign Up'}</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full p-2 mb-4 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-2 mb-4 border rounded"
        />

        {!isLogin && (
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            className="w-full p-2 mb-4 border rounded"
          />
        )}

        <button onClick={handleEmailAuth} className="bg-[#2E6C83] text-white p-2 rounded w-full">
          {isLogin ? 'Login' : 'Sign Up'}
        </button>

        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}

        <p className="mt-4 text-center">
          {isLogin ? "Don't have an account?" : 'Already have an account?'}
          <span onClick={() => setIsLogin(!isLogin)} className="text-blue-500 cursor-pointer ml-2">
            {isLogin ? 'Sign Up' : 'Login'}
          </span>
        </p>

        <p className="mt-4 text-center">
          <span onClick={handlePasswordReset} className="text-blue-500 cursor-pointer">
            Forgot Password?
          </span>
        </p>
      </div>
    </div>
  );
};

export default Auth;
